.chatbot-container {
  display: flex;
  height: calc(100vh - 100px);
  gap: 20px;
  padding: 20px;
  overflow: hidden;
  width: 100%;
}

.tagListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0; 
  width: 60%;
  max-width: 80%;
}

.tagEditorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: auto;
  overflow: hidden;
  background-color: #f9f9f9;
  min-width: 0;
  max-width: 450px;
  margin-right: 2%;
  /* width: 500px; */
}

.ticket-controls {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.search-input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  flex-grow: 1;
  min-width: 150px;
}

.filter-container {
  display: flex;
  gap: 80px;
  align-items: center;
}

.select-filter {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background-color: white;
  min-width: 150px;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f0f0f0;
  flex-wrap: wrap;
}

.chat-header-item {
  display: flex;
  align-items: center;
  gap: 58px;
  margin: 4px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 250px);
}

.message-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  max-width: 80%;
}

.message-container.customer {
  align-self: flex-start;
}

.message-container.server {
  align-self: flex-end;
}

.message-bubble {
  padding: 12px;
  border-radius: 12px;
  max-width: 100%;
  word-break: break-word;
}

.message-bubble.customer {
  background-color: #3B82F6;
  color: white;
  border-top-left-radius: 2px;
}

.message-bubble.server {
  background-color: #E5E7EB;
  color: #1F2937;
  border-top-right-radius: 2px;
}

.message-timestamp {
  font-size: 11px;
  margin-top: 4px;
  color: #6B7280;
  align-self: flex-end;
  cursor: pointer;
}

.message-input-container {
  display: flex;
  padding: 12px 16px;
  border-top: 1px solid #e0e0e0;
  background-color: white;
}

.message-input {
  flex: 1;
  border: 2px solid #D1D5DB;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}

.message-input input {
  flex: 1;
  padding: 12px;
  border: none;
  outline: none;
  font-size: 14px;
}

.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  cursor: pointer;
  color: #3B82F6;
}

.ticket-table-container {
  overflow-x: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.ticket-table {
  width: 100%;
  border-collapse: collapse;
}

.ticket-table th {
  background-color: #f3f4f6;
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid #e0e0e0;
}

.ticket-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.empty-chat-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #6B7280;
  text-align: center;
  padding: 20px;
}

.ticket-tag-badge {
  display: inline-block;
  padding: 4px 8px;
  background-color: #E5E7EB;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.tag-selector-container {
  position: relative;
}

.custom-tag-input {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 8px;
  margin-top: 4px;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (max-width: 1024px) {
  .chatbot-container {
    flex-direction: column;
    height: auto;
  }
  
  .tagListContainer {
    max-width: 100%;
  }
  
  .chat-messages {
    max-height: 50vh;
  }
}
